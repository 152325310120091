import axios from "axios";

const headers = {
  "Api-key": `${process.env.REACT_APP_BREVO_API_KEY}`,
  "Content-Type": "application/json",
  Accept: "application/json",
};

export function requestSendSMSNotification(payload) {
  var data = JSON.stringify({
    type: "transactional",
    unicodeEnabled: false,
    sender: payload.sender,
    recipient: payload.recipient,
    content: payload.content,
    organisationPrefix: payload.organisationPrefix,
  });

  return axios.request({
    headers: headers,
    method: "post",
    url: `https://api.brevo.com/v3/transactionalSMS/sms`,
    data,
  });
}

export function requestSendCustomEmailNotification(data) {
  console.log("payload data: ", data);
  return axios.request({
    method: "post",
    url: `${process.env.REACT_APP_DOMAIN}/notification/send/email`,
    data,
  });
}

export function requestSendPDFEmailNotification(payload) {
  const formData = new FormData();
  formData.append("transactionalEmail", payload.transactionalEmail);
  formData.append("policyNumber", payload.policyNumber);

  const response = fetch(
    `${process.env.REACT_APP_DOMAIN}/notification/send/emailAndPDFAttachment`,
    {
      method: "POST",
      body: formData,
    }
  );

  return response;
}

export function requestSendCSVEmailNotification(payload) {
  const formData = new FormData();
  // formData.append('transactionalEmail', transactionalEmail);
  // formData.append('csvRequest', csvRequest);

  // ------------ transactionalEmail ----------------
  // SendTransactionalEmailsDTO {
  //   public Map<String, String> sender;
  //   public List<Map<String, String>> to;
  //   public Map<String, String> replyTo;
  //   public List<Map<String, String>> attachment;
  //   public String subject;
  //   public String htmlContent;
  //   public Map<String, String> params;
  //   public List<String> tags;

  //    sendSmtpEmail.subject = "My {{params.subject}}";
  //    sendSmtpEmail.htmlContent = "<html><body><h1>Common: This is my first transactional email {{params.parameter}}</h1></body></html>";
  //    sendSmtpEmail.sender = { "name": "John", "email": "example@example.com" };
  //    sendSmtpEmail.to = [{ "email": "example@brevo.com", "name": "sample-name" }];
  //    sendSmtpEmail.replyTo = { "email": "example@brevo.com", "name": "sample-name" };
  //    sendSmtpEmail.headers = { "Some-Custom-Name": "unique-id-1234" };
  //    sendSmtpEmail.params = { "parameter": "My param value", "subject": "common subject" };
  //sendSmtpEmail.attachment = [{"url":"https://attachment.domain.com/myAttachmentFromUrl.jpg", "name":"myAttachmentFromUrl.jpg"}, {"content":"base64 example content", "name":"myAttachmentFromBase64.jpg"}]

  //------------- csvRequest ----------------------------------------------------
  // CsvRequestDTO {
  //   private String underwriterId;
  //   private String[][] data;
  //   private String password;
  // }

  return axios.request({
    method: "post",
    url: `${process.env.REACT_APP_DOMAIN}/notification/send/emailAndCSVAttachment`,
    formData,
  });
}

export function requestSendEmailNotification(payload) {
  var data = JSON.stringify(payload);

  return axios.request({
    headers: headers,
    method: "post",
    url: `https://api.brevo.com/v3/smtp/email`,
    data,
  });
}

export function requestGetEmailTemplates() {
  return axios.request({
    headers: headers,
    method: "get",
    url: `https://api.brevo.com/v3/smtp/templates?limit=12&offset=0&sort=desc`,
  });
}

export function requestGetSmsReport(data) {
  return axios.request({
    headers: headers,
    method: "get",
    url: `https://api.brevo.com/v3/transactionalSMS/statistics/events?limit=12&startDate=${data.startDate}&endDate=${data.endDate}&offset=0&phoneNumber=${data.phoneNumber}&sort=desc`,
  });
}

export function requestGetEmailPrintOut(data) {
  return axios.request({
    headers: headers,
    method: "get",
    url: `https://api.brevo.com/v3/smtp/emails/${data}`,
  });
}

export function requestCreateNotification(data) {
  return axios.request({
    method: "post",
    url: `${process.env.REACT_APP_DOMAIN}/notification/create`,
    data,
  });
}

export function requestGetPolicyNotifications(data) {
  return axios.request({
    method: "get",
    url: `${process.env.REACT_APP_DOMAIN}/notification/policy/${data}`,
  });
}

export function requestGetNotificationsInApp(data) {
  return axios.request({
    method: "get",
    url: `${process.env.REACT_APP_DOMAIN}/notification/app/${data}`,
  });
}

export function requestGetEmailReport(data) {
  return axios.request({
    method: "get",
    url: `${process.env.REACT_APP_DOMAIN}/notification/email/${data.email}/${data.startDate}/${data.endDate}`,
  });
}

// export function emailNotification(payload) {
//   var myHeaders = new Headers();
//   myHeaders.append("Accept", headers["Accept"]);
//   myHeaders.append("Api-key", headers["Api-key"]);
//   myHeaders.append("Content-Type", headers["Content-Type"]);
//   //myHeaders.append("Cookie", "__cf_bm=cLSPwwUQB4CowYWh6SNv3E4DNuIpBEeAuhgD8EQGDUc-1696192630-0-AdxxIavOPPD4Mq76anV/nJ6aKVy+Q/K0njZGNO17Pt5wyENieNLE71EDKFQsQIB47TK6qh2TvOfrzn6KFH9mT9c=");

//   var raw = JSON.stringify({
//     sender: {
//       email: "support@choicebenefits.co.za",
//       name: "Choice SUPPORT",
//     },
//     subject: "7 Days Payment Overdue",
//     templateId: 2,
//     params: {
//       firstName: "Gary",
//       policyNumber: "4747",
//       date: "4th of January 2023",
//     },
//     messageVersions: [
//       {
//         to: [
//           {
//             email: "lotharsessentials@gmail.com",
//             name: "Lothar Mohlala",
//           },
//         ],
//         params: {
//           firstName: "Gary",
//           policyNumber: "4747",
//           date: "4th of January 2023",
//         },
//         subject: "We are happy to be working with you",
//       },
//     ],
//   });

//   var requestOptions = {
//     method: "POST",
//     headers: myHeaders,
//     body: raw,
//     redirect: "follow",
//   };

//   fetch("https://api.brevo.com/v3/smtp/email", requestOptions)
//     .then((response) => response.json())
//     .then((result) => console.log(result))
//     .catch((error) => console.log("error", error));
// }

// export function smsNotifcation(payload) {
//   var myHeaders = new Headers();
//   myHeaders.append("Accept", headers["Accept"]);
//   myHeaders.append("Api-key", headers["Api-key"]);
//   myHeaders.append("Content-Type", headers["Content-Type"]);
//   //myHeaders.append("Cookie", "__cf_bm=xR7_d5dnf42haZsh5vTBi1BZdNShSKwlsWHgDUS_iGc-1696195683-0-Ac9ERax3fx0rtp7tbQah9O384EnjlIuuVmqVDigo0U+5bggOFYz0Elr901IIy1rlWKH4sMthI/DuIkNWXO1LEyM=");

//   var raw = JSON.stringify({
//     type: "transactional",
//     unicodeEnabled: false,
//     sender: "Choice",
//     recipient: "27810149665",
//     content: "Testing Sms 2",
//     organisationPrefix: "Choice Benefits",
//   });

//   var requestOptions = {
//     method: "POST",
//     headers: myHeaders,
//     body: raw,
//     redirect: "follow",
//   };

//   fetch("https://api.brevo.com/v3/transactionalSMS/sms", requestOptions)
//     .then((response) => response.json())
//     .then((result) => console.log(result))
//     .catch((error) => console.log("error", error));
// }

// export const getEmailTemplates = async (data) => {
//   await axios
//     .get(
//       "https://api.brevo.com/v3/smtp/templates?limit=50&offset=0&sort=desc",
//       { headers: headers }
//     )
//     .then((res) => {
//       console.log("res.data: ", res.data);
//       return res.data;
//       // dispatch({
//       //   type: GET_EMAIL_TEMPLATES,
//       //   payload: res.data,
//       // });
//     })
//     .catch((err) => {
//       console.log("err.response: ", err.response);
//       return err.response;
//       // dispatch({
//       //   type: GET_ERRORS,
//       //   payload: err.response,
//       // });
//     });
// };
